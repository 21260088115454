import { JobOfferAnsweredQuestion } from '../JobOffers/types';

const parseQuestionsFilter = formData => {
  const parsedQuestions: Array<JobOfferAnsweredQuestion> = [];

  for (let key in formData) {
    const questionId = parseInt(key.split('-')[1]);
    const answers = Array.isArray(formData[key])
      ? formData[key]
      : formData[key] !== undefined && formData[key] !== null
        ? [formData[key]]
        : [];

    if (questionId && answers.length > 0) {
      parsedQuestions.push({
        questionId: questionId,
        answers: answers,
      });
    }
  }

  return parsedQuestions;
};
export default parseQuestionsFilter;
