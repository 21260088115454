import { useState } from 'react';
import { APIRequestResult } from './api/BaseAPIClient';
import { useErrorMessageHandler, usePagination, useSharedContext } from '.';

interface UsePaginatedTableDataOut<T> {
  count: number | undefined;
  page: Array<T>;
  isLoadingPage: boolean;
  pageReseted: boolean;
  pageNumber: number;
  pageSize: number;
  loadPage: (pageNumber?: number) => Promise<void>;
  clear: () => void;
  setPage: (p: number) => void;
  resetPage: () => void;
  initPagination: (p?: number) => void;
}

export const usePaginatedTableData = function PaginatedTable<T>(
  count: () => Promise<APIRequestResult<number>>,
  page: (pageNumber: number, pageSize: number) => Promise<APIRequestResult<Array<T>>>,
  size?: number,
): UsePaginatedTableDataOut<T> {
  const [dataCount, setDataCount] = useState<number>();
  const [data, setData] = useState<Array<T>>([]);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);

  const { pageNumber, pageSize, pageReseted, setPage, resetPage, initPagination } = usePagination(size);

  const { errorMessageHandler } = useErrorMessageHandler();

  const { showOverlay, hideOverlay } = useSharedContext();

  const loadPage = async (p?: number): Promise<void> => {
    showOverlay();
    if (!p) {
      p = pageNumber;
    }

    setIsLoadingPage(true);

    const [resPage, resCount] = await Promise.all([page(p, pageSize), count()]);

    if (resCount.hasError) {
      setData([]);
      errorMessageHandler(resCount);
      setIsLoadingPage(false);
      hideOverlay();
      return;
    }

    if (resCount.data !== undefined) {
      setDataCount(resCount.data);
    }

    if (resPage.hasError) {
      errorMessageHandler(resPage);
      setData([]);
      setIsLoadingPage(false);
      hideOverlay();
      return;
    }

    if (resPage.data) {
      setData(resPage.data);
    }

    hideOverlay();
    setIsLoadingPage(false);
  };

  const clear = (): void => {
    setData([]);
    setDataCount(undefined);
  };

  const resetDataPage = (): void => {
    clear();
    resetPage();
  };

  const initDataPagination = (): void => {
    clear();
    initPagination();
  };

  return {
    count: dataCount,
    page: data,
    isLoadingPage,
    pageReseted,
    pageNumber,
    pageSize,
    loadPage,
    clear,
    setPage,
    resetPage: resetDataPage,
    initPagination: initDataPagination,
  };
};
